import React from "react"
import Layout from "../components/layout"

import { Link, graphql, useStaticQuery } from "gatsby"
import "../styles/main.scss"
// import konyvekStyles from "./konyvek.module.scss"

const KonyvekPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(index/konyvek)/" } }
        sort: { order: DESC, fields: [frontmatter___id] }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              id
            }
            fields {
              slug
            }
            excerpt(pruneLength: 280)
            html
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <div className="container">
        <div className="row">
          <div className="sm-col-2"></div>
          <div className="sm-col-8">
            <h1>Könyvek</h1>
            <div className="container">
              <ol>
                {data.allMarkdownRemark.edges.map(edge => {
                  return (
                    <li className="" key={edge.node.id}>
                      <Link to={`/${edge.node.fields.slug}`}>
                        <h2>{edge.node.frontmatter.title}</h2>
                        {/* <p>{edge.node.excerpt}</p> */}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: edge.node.html,
                          }}
                        ></div>
                      </Link>
                    </li>
                  )
                })}
              </ol>
            </div>
          </div>
          <div className="sm-col-2"></div>
        </div>
      </div>
    </Layout>
  )
}

export default KonyvekPage
